.dropzone--primary {
  position: relative;
  pointer-events: none;
  display: flex;
  align-items: center;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: all;
    cursor: pointer;
  }


}

.dropzone--primary__thumb {
  min-width: 51px;
  width: 51px;
  height: 51px;
  box-sizing: border-box;
  margin-right: 10px;
  background: url("../../../../../images/general/dropzone.svg") center / contain no-repeat;
  overflow: hidden;
  border-radius: 50%;

  &.fas {
    border-radius: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    background-color: #fff;
  }
}

.dropzone--primary__title {
  display: flex;
  flex-direction: column;
  span {

    .font(#828282 12px 15.6px);
  }
  label {
    .font(@alfa-color 15px 17.78px);
    width: 100%;
    margin-bottom: 6px;

  }
}


