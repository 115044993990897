.custom-select {
  .font(white 15px 36px, {
    text-align: left;
  });
  background-color: rgba(0, 0, 0, 0.2);
  //background-color: @thin-color;
  //border: 1px solid @brand-color;
  border-radius: 2px;
  box-sizing: border-box;
  position: relative;
  padding: 0 16px;
  cursor: pointer;
  max-width: 15rem;

  label {
    .font(@danger @font-small 19px);
    pointer-events: none;
    position: absolute;
    bottom: 100%;
    right: 16px;
  }

}

.custom-selectWr__selected {

}

.custom-select__selection {
  position: absolute;
  top: 100%;
  background-color: rgb(43, 43, 43);
  //background-color: @thin-color;
  //left: 10px;
  //right: 10px;
  left: 0;
  right: 0;
  padding: 0 10px;
  //border: 1px solid @brand-color;
  border-radius: 0 0 2px 2px;
  //border-radius: 0 0 15px 15px;
  z-index: 1;
  overflow: auto;
  max-height: 10rem;
  //height: ~"max(200px, 100%)";
}

.custom-select__options {
  padding: 0 16px;
  margin-right: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover, &.active {
    color: @thin-color
  }

  &:hover {
    background-color: fade(@black-darker, 30%);
  }

  &.active {
    background-color: @brand-color;
  }
}
