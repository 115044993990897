.app {
  .md(min, {
    background: url('../../../../assets/images/general/bg-general.jpg') top / cover no-repeat #871628 fixed;
    height: 100vh;
    max-height: 100vh;
    padding: 0 0 30px 0;
    overflow: scroll;
    .animate({
      .action('fade-in');
    });
  });
  background: url('../../../../assets/images/general/bg-general.jpg') center center / cover no-repeat #871628 fixed;
}

.app__head {
  padding: @app-head-pg;
  position: relative;
  z-index: 0;
  margin-top: 75px;
  background: transparent;

  .md({
    z-index: 5;
    top: 0;
    margin-top: 0px;
    padding-bottom: 0px;
    //background: linear-gradient(180deg, #70c8ec 21%, #fff 70%);
  });
}

.app__body {
  display: flex;
  position: relative;

  .md(min, {
    height: @app-body-height;
   // max-height: 700px;
    height: 600px;
  });

  .md({
    flex-wrap: wrap;
    margin-top: 40px;
  });

  .sm({
    margin-top: 10px;
    padding-bottom: 20px;
  });
}

.app__body-side {
  min-width: 202px;
  display: flex;
  flex-direction: column;

  .animate({
    .action('fade-in');
  });

  .md({
    width: 100%;
    margin-bottom: 25px;
  });

  .first({
    .md({
      order: 2;
    });
  });

  .last({
    .md({
      order: 1;
    });
  });
}

.app__body-main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: calc(100% - 444px);
  margin-left: 20px;

  .animate({
    .action('fade-in');
  });

  .md(min, {
    //max-height: 700px;

    &:not(:last-child) {
      margin: 0 10px;
    }

    //&:last-child {
    //  margin-left: 20px;
    //}
  });

  .md({
    order: 1;
    margin: 0 0 20px;
    width: 100%;
  });
}

.app__block {
  .flex(column);
  background-color: #fff;
  box-sizing: border-box;
  flex: 1 1 auto;
  overflow: hidden;
  //box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  //border-radius: 4px;
  border-radius: 10px;
  color: #303440;

  .md({
    order: 2;
  });

  h4 {
    .font(@alfa-color 24px 32px);
  }

  .new-comment-container {
    margin: 30px auto;
    .new-comment-headline {
      .font(@ultra-color 15px 19px);
      margin-bottom: 9px;
    }
  }

  .app__block {
    box-shadow: none;
  }

  .md(min, {
    &[class$="block"] {
      //height: 100%;
    }
  });
}

.app__block-head {
  .font(#0d2c90 16px 23px, {
    font-family: @brand-family-2;
    text-align: left;
  });
  box-sizing: border-box;
  border-radius: 6px 6px 0 0;
  margin-top: 14px;
  margin-left: 15px;

  h2 {
    .font(@alfa-color 14px 16.59px 600);
    font-family: 'VTB55', sans-serif;
    font-family: var(--ls-brand-family-2);
    text-align: center;
  }
  > p {
    .font(#333 12px 15.6px 400);
    text-align: center;
    margin: 7px auto 0;
    max-width: 500px;
  }
}

.app__block-body {
  flex: 1 1 auto;
  overflow: hidden;
  height: 100%;
  position: relative;
}

.app__error {
  .font(@brand-color @h-font-base 1);
  .flex.center();
  height: 100vh;
}
