.fade-desc {
  background: rgba(0, 0, 0, 0.04);
  border: 1px solid #E4DCD5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  margin: 20px;
  position: relative;

  span{
    position: absolute;
    width: 10px;
    height: 10px;
    background: @brand-color;
    right: 0;
    bottom: 12px;
    border-radius: 50%;

    &:hover{
      ~ .fade-desc__tooltip {
        display: block;
      }
    }
  }
}

.fade-desc__title {
  .font(#333 12px 14px);
  font-family: @brand-family-2;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 7px;
  max-width: calc(100% - 170px);
}

.fade-desc__text {
  .font(#333 12px 1.3);
  letter-spacing: -0.02em;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fade-desc__tooltip {
  .font(#333 12px 1.3);
  letter-spacing: -0.02em;
  word-break: break-all;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 10px;
  z-index: 22;

  background: #f0e6de;
  border: 1px solid #E4DCD5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
}
