.profile-user {
  .font(@white 12px 16px, {
    font-family: @brand-family-2;
    text-align: center;
  });
  margin-right: 0.5rem;
  //margin: auto;
  cursor: pointer;
  display: flex;
  align-items: center;

  img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    object-fit: contain;
    object-position: center;
    margin-right: 6px;
  }
}

.profile-user__name {
  max-width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profile-user__avatar {
  &.fas {
    img {
      border-radius: 0;
    }
  }
}

.profile-divider {
  width: 100%;
  height: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0.5rem auto;
}

.portal__data-test-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #fff;
  padding: 30px 75px;
  border-bottom: 1px solid #878a8d;
  .sm({
    grid-template-columns: 1fr;
    padding: 30px 35px;
  });
  grid-column-gap: 1rem;

  .user-score {
    .font(#878a8d 20px 26px);
    margin-bottom: 1rem;
  }
}

.portal__data-test-advantages {
  .font(#A9A9A9 15px 23px);

  li {
    list-style: initial !important;
    margin-left: 1rem;

    &::marker {
      font-size: 12px;
    }
  }

  li:first-child {
    margin-top: 10px;
  }
}

.portal__data-test-button {
  .font(#0841dc 13px 16px);
  padding: 8px 20px;
  background: #f0f2f5;
  border-radius: 4px;
  width: 243px;
  height: 50px;
  text-transform: uppercase;
  margin-bottom: 12px;

  &:hover,
  &.active {
    background-color: @brand-color;
    color: #fff;
  }
}
