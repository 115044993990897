.time-table {
  height: calc(70% - 25px);

  + div {
    margin-top: 25px;
  }

  .md({
    height: 366px;
    flex: none;
  });
}

.time-table__wrapper {
  //margin-bottom: 11px;
  .active {
    background-color: #0841DC;
     * {
      color: #fff!important;
    }
  }
}

.time-table__item {
  padding: 10px;
  cursor: pointer;
  background-color: #f0f2f5;
  box-shadow: 0 1px 0 #d5d7de;
  border-radius: 10px;
  margin: 18px 5px;
  //cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }

  ol,
  ul {
    li {
      list-style: initial !important;
      margin-left: 0.5rem;
    }
  }

  &:hover,
  &.active {
    background-color: #0841DC;
    * {
      color: #fff!important;
    }
  }

  &.active {
    pointer-events: none;
  }
}

.time-table__item-time {
  .font(#878a8d @font-small 13px);
  //margin-left: 25px;

  .time-table__item:hover & {
    color: #fff;
  }
}

.time-table__item-title {
  .font(#303440 @font-small 13px);
  //margin-left: 25px;
  margin: 5px 0px;

  .time-table__item:hover & {
    color: #fff;
  }
}


.activities {
  padding: 30px 30px 34px 30px;
  .activities__title {
    * {
      font-size: 24px!important;
      line-height: 130%!important;
      letter-spacing: -0.01em!important;
      color: #0D2C90!important;
      margin-bottom: 20px;
    }
  }

  .activities__subtitle {
    * {
      font-size: 18px!important;
      line-height: 23px!important;
      letter-spacing: -0.01em!important;
      color: #0D2C90!important;
      margin-bottom: 12px;
    }
  }

  .activities__description {
    * {
      margin: 10px 0 10px 0;
      font-size: 12px!important;
      line-height: 130%!important;
      letter-spacing: -0.01em!important;
      color: #303440!important;
    }
  }

  .activities__gameName {
    * {
      .clear();
      font-size: 18px!important;
      line-height: 23px!important;
      letter-spacing: -0.01em!important;
      color: #0D2C90!important;
      margin-top: 20px;
    }
  }

  .activities__gameDescription {
    * {
      font-size: 12px!important;
      line-height: 130%!important;
      letter-spacing: -0.01em!important;
      color: #303440!important;
      margin-top: 12px;
    }
  }

}
