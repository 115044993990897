.quest-container {
  padding: 30px 0 0 0;
  .sm({
    padding: 30px 35px 50px 35px !important;
  });

  h4 {
    margin-bottom: 4px;
    .font(white 24px 32px);

    a {
      color: white !important;
      margin-left: .25rem;
    }
  }

  .quest-description {
    padding: 0 30px !important;
    margin: 10px auto;
    h5 {
      font-weight: bold;
      .font(white 13px 17px);
      text-transform: uppercase;
    }
    .font(@alfa-color 12px 16px);
    li {
      margin-left: 1rem;
      list-style: initial !important;
    }
  }
  .quest-container {
    padding-bottom: 30px;

    .quest {
      height: 30rem;
    }
  }
}

.quest-container_text {
  background: #F0F2F5;
  margin: 0 20px;
  padding: 14px;

  h4 {
    font-size: 18px;
    line-height: 23px;
    color: #303440;
  }

  p {
    font-size: 12px;
    line-height: 130%;
    color: #303440;
  }
}

.quest-links {
  display: flex;
  padding: 0 30px !important;
  margin-bottom: 10px;
  a {
    .font(@white 14px 17px);
    display: inline-block;
  }
  justify-content: space-between;
  flex-wrap: wrap;
}

.quest-result {
  .font(black 14px 18px);

  p {
    margin-bottom: 1rem;
  }
}

.quest-question-title {
  color: #303440!important;
  font-size: 15px!important;
  line-height: 1.3!important;
  margin-bottom: 5px;
}
