.swiper-container {
  position: relative;

  ~ [class*="swiper-button"] {
    position: absolute;
    border-left: 2px solid;
    border-top: 2px solid;
    border-radius: 0;
    //width: 10px;
    width: 12px;
    //height: 10px;
    height: 12px;
    padding: 0;
    top: 50%;
    background-color: transparent;
    z-index: 1;

  }

  ~ .swiper-button-prev {
    border-color: white; // #878A8D;
    left: -14px;
    transform: translateY(-50%) rotate(-45deg);
  }
  ~ .swiper-button-next {
    border-color: white; // #878A8D;
    right: -14px;
    transform: translateY(-50%) rotate(135deg)
  }
}
