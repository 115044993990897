.photo-use {
  img {
    object-fit: cover;
    object-position: center;
  }
}

.photo-use__popup {
  width: 70vw;
  vertical-align: middle;
  margin: 30px 15px;
  height: calc(100vh - 90px);
  display: inline-flex;
  position: relative;
  background: #ffffffa1;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid @brand-color;

  img {
    margin: auto;
    object-fit: contain;

  }
}

.photo-use__thumbnail {
  height: 126px;
  background-color: #f0f0f0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  margin-bottom: 9px;

  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

}

.photo-use__like {
  .font(#A9A9A9 12px 24px);
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);;
  border-radius: 4px;
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  cursor: pointer;
  left: 6px;
  bottom: 6px;
  padding: 0 11px 0 4px;

  svg {
    path {
      fill: #A9A9A9;;
    }
  }

  &.active {
    svg {
      path {
        fill: #A9A9A9;
      }
    }
  }

  span {
    color: #A9A9A9;
    margin: 0 6px 0 3px;
  }
}

.photo-use__data {
  img {
    border-radius: 50%;
  }

  img, svg {
    width: 16px;
    height: 16px;
  }
  img, svg {
    margin-right: 5px;
  }
}

.photo-use__text {
  .font(#333 12px 15.6px);
  letter-spacing: -0.02em;
  margin-bottom: 6px;

}

.photo-use__name {
  display: flex;
  align-items: center;
  .font(#828282 12px 15.6px);

  + div {
    .font(#828282 12px 15.6px);
    display: flex;
    align-items: center;
    margin-top: 6px;
  }
}

.photo-use__avatar {
  display: flex;
  align-items: center;
  .font(#828282 12px 15.6px);


  ~ div {
  }
}
