.cloud{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-smooth: always;
  margin-top: 15px;
  div {
    color: #2D82C6;
    height: 265px;
  }
}

.cloud_container {
  overflow: inherit;
}


.tag-cloud {
  flex: 1;
}

.tag-cloud > div {
  transition: 1.4s;
}

.tag-item-wrapper {
  cursor: pointer;
}

.tag-item-wrapper:hover {
  z-index: 100;
}
