.photo {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  .app__block-head {
    padding: 12px 0;
  }
}


.photo__main {
  padding: 20px 30px 40px;

  .md({
    max-height: 428px;
  });
}

.photo__upload {
  position: absolute;
  top: 28px;
  right: 3px;
  cursor: pointer;
  z-index: 4;
  .font(#fff 12px 21px, {
    font-family: @brand-family-2;
  });
  background-color: @brand-color;
  padding: 0 10px;
  border-radius: 4px 0 4px 4px;

  &:before {
    content: '';
    position: absolute;
    top: -7px;
    right: 3px;
    border: 6px solid transparent;
    border-right: 8px solid #CF5F0D;
    transform: rotate(-54deg);
    z-index: -3;
    border-bottom-width: 10px;
  }
}

.photo__main {

}

.photo__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(308px, 1fr));
  grid-gap: 20px 6px;
  //padding: 0 7px;

  > svg {
    width: 100%;
    height: 150px;
  }
}

.new-photo-container {
  h4 {
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 10px;
  }

  p.new-photo-description {
    .font(#A9A9A9 12px 16px);
    margin: 10px 0 30px;
  }
}
