.local {
  color: #031C76;
  border: 1px solid rgba(3, 28, 118, 0.23);
  border-radius: 4px;
  padding: 6px;
}

.local__item {
  display: block;
  cursor: pointer;
  .font(rgba(3, 28, 118, 0.23) 10px 15px);

  &.active {
    color: @brand-color;
  }
}
