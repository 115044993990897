.app-calendar {
  font-size: 20px;
  height: 100%;
  display: grid;
  grid-gap: 30px;
  padding: 30px;

  .animate({
    .action("fade-in");
  });

  .rbc-show-more{
    font-size: 11px;
  }

  .local {
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }

  > h1 {
    font-size: 25px;
    text-align: center;
    margin: 20px 0;
  }

  .rbc-event {
    all: initial;
  }

  .rbc-event-label {
    .font(@white-lighter 14px 20px, {
      font-family: @brand-family
    });

    background-color: @danger;
    line-height: 20px;
    padding: 0 10px;
  }

  .rbc-event-content {
    h1 {
      .font(@white-lighter 14px 20px, {
        font-family: @brand-family
      });

      display: flex;
      padding: 0 10px;
      cursor: pointer;

      span {
        max-width: calc(100% - 40px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      svg {
        width: 20px;
        height: 20px;
        margin-left: auto;
      }
    }
  }
}

.app-calendar__back{
  a{
    .font(@beta-color 16px 20px 600);

    display: flex;
    align-items: center;

    &:hover{
      color: @brand-color;
      svg{
        fill: @brand-color
      }
    }
  }

  svg{
    width: 20px;
    margin-right: 8px;
    transition: @transition;
  }
}

.app-calendar__field {
  flex: auto;
}

.app-calendar__description {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  text-align: center;
  z-index: 4;
  overflow: scroll;
  background-color: @meta-color;

  .animate({
    .action("fade-in", @duration: .3s);
  });

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
}

.app-calendar__description-container {
  display: inline-block;
  vertical-align: middle;
  margin: 30px 15px;
  width: calc(100% - 50px);
  text-align: center;
}

.app-calendar__time {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-gap: 20px;
  margin-top: 30px;
}

.rbc-day-bg {
  cursor: pointer;
}
