.grid-use {
  //display: flex;
  align-items: flex-start;
  padding: 0 20px 10px 10px;
  //padding: 18px 14px;
  /* Primary/white */
  background: #fff;
  /* Secondary/back */
  border: 1px solid #F0F2F5;
  box-sizing: border-box;
  box-shadow: 0 33px 38px rgba(0, 0, 0, 0.03), 0 9.94853px 11.4559px rgba(0, 0, 0, 0.0195477), 0 4.13211px 4.75819px rgba(0, 0, 0, 0.015), 0 1.4945px 1.72094px rgba(0, 0, 0, 0.0104523);
  border-radius: 10px;
  max-width: 206px;

  img {
    object-fit: cover;
    object-position: center;
    border-radius: 1000px;
  }

  .xs({
    display: block;
  });
}

.grid-use__thumbnail {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  .xs(min, {
    min-width: 180px;
    max-width: 180px;
  })
  //height: 126px;
  //background-color: #f0f0f0;
  //border-radius: 6px;
  //overflow: hidden;
  //position: relative;
  //margin-bottom: 9px;


}

.grid-use__avatar {
  margin-right: 10px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
  svg {
    width: 38px;
    height: 38px;
  }
}

.grid-use__name {
  > div {
    .font(#303440 12px 15.6px);
  }

  span {
    display: block;
    .font(#828282 12px 15.6px);

    &:first-of-type {
      margin: 4px 0;
    }
  }
}

.grid-use__like {
  .font(#333 12px 24px);
  background-color: #F0F2F5;
  border-radius: 4px;
  backdrop-filter: blur(4px);
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 0 11px 0 4px;
  margin-top: 10px;

  &:last-child {
    margin-top: 6px;
  }

  svg {
    path {
      fill: #A9A9A9;
    }
  }

  &.active {
    svg {
      path {
        fill: #A9A9A9;
      }
    }
  }

  span {
    color: #A9A9A9;
    margin: 0 6px 0 3px;
  }
}

.grid-use__data {
  margin-top: 10px;
  //.xs(min, {
  //  margin: 0;
  //  flex: auto;
  //  padding-left: 20px;
  //})
}

.grid-use__text {
  .font(#303440 15px 19px);
  letter-spacing: -0.02em;
  margin: 10px auto;
}

.photo-use__name {
  display: flex;
  align-items: center;
  .font(#303440 12px 15.6px);

  + div {
    .font(#828282 12px 15.6px);
    display: flex;
    align-items: center;
    margin-top: 6px;
  }
}

.photo-use__avatar {
  display: flex;
  align-items: center;
  .font(@alfa-color 12px 15.6px);


  ~ div {
  }
}
