.talent-use {
  background: rgba(135, 138, 141, 0.2);
  //border: 1px solid #FFEADC;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 7px;

  img {
    object-fit: cover;
    object-position: center;
  }
}

.talent-use__like, .talent-use__to {
  background-color: @alfa-color;
  .font(#333 12px 24px);
  border-radius: 4px;
  margin: 6px 0;
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 6px 4px 6px;

  &.active {
    svg {
      path {
        fill: #A9A9A9;
      }
    }
  }

  span {
    color: #A9A9A9;
    margin: 0 6px 0 3px;
  }
}

.talent-use__text {
  .font(#333 12px 15.6px);
  letter-spacing: -0.02em;
  margin-bottom: 6px;

}

.talent-use__name {
  display: flex;
  align-items: center;
  .font(#fff 15px 17.78px);

  + div {
    .font(#828282 12px 15.6px);
    display: flex;
    align-items: center;
    margin-top: 6px;
  }
}

.talent-use__avatar {
  display: flex;
  align-items: center;
  .font(#828282 12px 15.6px);
  margin-right: 5px;

  img, svg {
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }

  + span {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }

}

.talent-use__head {
  margin-bottom: 9px;
}

.talent-use__body {
  > div {
    //display: inline-flex;
    //white-space: nowrap;
    //flex-wrap: nowrap;
    margin-right: 30px;
  }

  .talent-use_talent {
    .font(@alfa-color 15px 19px);
    margin: 10px auto;
  }

  .talent-use_city {
    .font(rgba(169, 169, 169, 1) 12px 16px);
  }

  &:first-of-type {
    margin-top: 8px;
    margin-bottom: 7px;
  }

  svg {
    margin-right: 4px;
  }

  > span {
    &:first-of-type {
      margin: 4px 0;
    }
  }

  span {
    display: block;
    .font(#828282 12px 15.6px);

    b {
      color: #333;
    }


  }
}
