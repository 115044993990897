.preloader {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #70c8ec 21%, #fff 70%);
  .flex.center();
  //.preloaderSpinner();
  //.preloaderText();
  .preloaderImage();
}

