.schedule-details {
  height: ~"min(234px, 30%)";
  background: url('../../../../../../assets/images/shared/rectangle2.png') center / cover repeat #fff;
  padding: 15px;
  overflow: hidden;
  white-space: nowrap;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .schedule-details__wrapper {
    background: url('../../../../../images/shared/line-to.png') center / cover no-repeat;


    border: 3px solid #7e7e7e;

    padding: 10px;
    overflow: hidden;
    max-width: 440px;
    //p, div, pre, span, img, a, strong, b, s, br {
    //  &::selection {
    //    background-color: @brand-color;
    //    color: @thin-color
    //  }
    //}

    //a {
    //  color: @brand-color
    //}

    &:hover {
      span {
        animation-play-state: paused !important;
      }
    }

    span {
      .font(#fff 16.15px 19.14px, {
        font-family: @brand-family-2;
        text-transform: uppercase;
        letter-spacing: 0.03em;
      });
      display: inline-block;
      padding-left: 100%;
      animation: marquee 15s linear infinite;


    }
  }
}


@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
