.checkbox--primary {
  display: inline-block;
  position: relative;
  padding-left: 15px;

  &:hover {
    span {
      border-color: @brand-color;
    }
    label {
      color: @brand-color
    }
  }

  &.active {
    span {
      background-color: @brand-color;
    }
  }

  span, label {
    cursor: pointer;
  }

  span {
    width: 10px;
    height: 10px;
    border: 1px solid @alfa-color;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 5px;
  }

  label {
    .font(@alfa-color @font-base 19px, {
      text-align: center;
    });
  }
}
