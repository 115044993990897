.input--primary {
  position: relative;
  text-align: left;

  &.error {
    input, textarea {
      border-color: @danger
    }
  }

  input, select {
    .font(#303440 15px 42px);
    height: 44px;
    padding: 0 12px;
    border: 1px solid #0841DC;
    background: #fff;
    width: 100%;
    appearance: none;

    &:focus, &:valid {
      ~ label:last-child {
        display: none;
      }
    }
  }

  select{
    option {
      .font(#303440 15px 42px);
      padding: 0 12px;
      border: 1px solid #0841DC;
      background: #fff;
      width: 100%;
    }
  }

  textarea {
    .font(#333 15px 17.78px);
    padding: 12px;
    border: 1px solid transparent;
    max-height: 300px;
    min-height: 82px;
    min-width: 100%;
    resize: none;
    &:focus, &:valid {
      ~ label:last-child {
        display: none;
      }
    }
  }

  div {
    .font(#303440 15px 25px);
    margin-bottom: 6px;
  }

  label {
    pointer-events: none;
    position: absolute;

    .first(type, {
      .font(@danger @font-small 19px);
      right: 16px;
      bottom: calc(100% - 36px);
    });

    .last({
      .font(#A9A9A9 15px 25px);
      left: 16px;
      top: 41px;
    });
  }
}
