.mobileNavigation() {
  position: fixed;
  right: 100%;
  bottom: 0;
  z-index: 1;
  width: 100%;
  background: #0D2C90; // linear-gradient(180deg, #70c8ec 21%, #fff 70%);
  //background: linear-gradient(180deg, #70c8ec 21%, #fff 70%);
  padding: 15px;
  box-sizing: border-box;
  height: calc(100% - 73px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  transition: transform .3s ease;

  &.active {
    transform: translateX(100%);
  }
}

.buttonToggle(@end: {}) {
  @end();
  position: relative;
  right: 0;
  width: 40px;
  height: 40px;
  margin-left: auto;
  cursor: pointer;
  background-color: transparent;

  &:before, &:after {
    content: "  ";
    background-color: @alfa-color;
    width: 100%;
    height: 4px;
    position: absolute;
    transition: @transition;
    border-radius: 10px;
    left: 0;
  }

  &:before {
    top: 12px;
  }

  &:after {
    bottom: 12px;
  }

  &:hover, &.active {
    &:before {
      transform: translate3d(0, 5px, 0) rotate(45deg);
    }

    &:after {
      transform: translate3d(0, -7px, 0) rotate(-45deg);
    }
  }
}

.buttonToggleClose(@end: {}) {
  @end();
  position: relative;
  right: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid @brand-color;
  line-height: 48px;
  text-align: center;

  &:hover{
    background-color: @brand-color;

    svg path{
      stroke: #fff;
    }
  }

  svg{
    vertical-align: middle;
  }
}

.mobile(@end: {}) {
  @end();
  position: relative;
  right: 0;
  width: 46px;
  height: 46px;
  margin-left: auto;
  cursor: pointer;
  background-color: transparent;

  &:before, &:after {
    content: "  ";
    background-color: @brand-color;
    width: 100%;
    height: 6px;
    position: absolute;
    transition: @transition;
    left: 0;
  }

  &:before {
    top: 8px;
  }

  &:after {
    bottom: 8px;
  }

  &.active {
    &:before {
      transform: translate3d(0, 9px, 0) rotate(45deg);
    }

    &:after {
      transform: translate3d(0, -15px, 0) rotate(-45deg);
    }

    span{
      opacity: 0;
    }
  }

  span {
    background-color: @brand-color;
    width: 100%;
    height: 6px;
    position: absolute;
    transition: @transition;
    top: 20px;
    left: 0;
  }
}


.preloaderSpinner(){

  &__spinner {
    position: absolute;
    width: 50px;
    height: 50px;

    .animate({
      .action("rotate", @duration: 2s, @fn: linear, @infinite: infinite);
    });

    .path {
      stroke: @brand-color;
      stroke-linecap: round;

      .animate({
        .action("dash", @duration: 1.5s, @fn: ease-in-out, @infinite: infinite);
      });
    }
  }
}


.preloaderText(){
  &__spinner {
    position: absolute;
    left: 0;
    width: 100%;
    top: calc(50% - 25px);
    min-height: 300px;
    transform: translate(0, -50%);
  }

  svg{
    width: 100%;
    height: 250px;
    font-size: 170px;

    &:not(:root){
      overflow: hidden;
    }
  }

  .text{
    fill: none;
    stroke-width: 5;
    stroke-linejoin: round;
    stroke-dasharray: 70 330;
    stroke-dashoffset: 0;
    -webkit-animation: stroke 6s infinite linear;
    animation: stroke 6s infinite linear;

    &:nth-child(5n + 1) {
      stroke: #008c95;
      -webkit-animation-delay: -1.2s;
      animation-delay: -1.2s;
    }

    &:nth-child(5n + 2){
      stroke: #b6b6b6;
      -webkit-animation-delay: -2.4s;
      animation-delay: -2.4s;
    }

    &:nth-child(5n + 3){
      stroke: #008c95;
      -webkit-animation-delay: -3.6s;
      animation-delay: -3.6s;
    }

    &:nth-child(5n + 4){
      stroke: #b6b6b6;
      -webkit-animation-delay: -4.8s;
      animation-delay: -4.8s;
    }

    &:nth-child(5n + 5){
      stroke: #008c95;
      -webkit-animation-delay: -6s;
      animation-delay: -6s;
    }
  }
}


@keyframes stroke {
  100% {
    stroke-dashoffset: -400;
  }
}


.preloaderImage(){
  &__spinner {
    position: absolute;
    left: 50%;
    width: 50vw;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}
