.button--hamburger {
  //.buttonToggle();
  .buttonToggleClose();
  background: #fff;
  border-color: #878A8D;

  svg {
    path {
      fill: black
    }
  }
}
