.fontFace('../../../fonts/rubik/regular', 'Rubik-Regular');
.fontFace('../../../fonts/rubik/bold', 'Rubik-Bold');
.fontFace('../../../fonts/rubik/black', 'Rubik-Black');
.fontFace('../../../fonts/cofo', 'CoFo-Sans');
.fontFace('../../../fonts/vtb', 'VTB55');


//.fontFace('../../../icons', 'Admin-Panel', {
//  font-display: block
//});

.typography(true, {
  body {
    .font(@ultra-color 0 1 400, {
      font-family: @brand-family
    });
  }

  a {
    text-decoration: none;
    transition: @transition;
    outline: 0;
  }

  h2 {
    color: @alfa-color;
    font-size: 24.36px;
  }

  h4 {
    font-size: 18.48px;
    color: @alfa-color;
  }

  h6 {
    font-size: 14px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    line-height: 1.2;
  }

  button {
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    transform: @transition;
  }

  input, textarea {
    position: relative;
    border-radius: 4px;
    border: 0;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: none;
    width: 100%;
    box-sizing: border-box;
    font-family: @brand-family;
    outline: none;
  }

  textarea {
    outline: none;
    padding: 0;
    border: 0;
    &:focus, &:valid {
      ~ label:last-child {
        display: none;
      }
    }
  }

  video {
    background-color: @beta-color;
    outline: none;
  }

  [contenteditable]{
  -webkit-user-select: auto;
  user-select: all;
  }
});

