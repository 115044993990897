:root {
  //fonts:
  --ls-brand-family: 'VTB-Group', sans-serif;
  --ls-brand-family-2: 'VTB-Group', sans-serif;
  --ls-brand-family-3: 'VTB-Group', sans-serif;
  --ls-font-less: 10px;
  --ls-font-small: 12px;
  --ls-font-base: 12px;
  --ls-font-large: 16px;
  --ls-h-font-small: 14px;
  --ls-h-font-base: 40px;

  //pages:
  --ls-transition: all .3s ease;
  //position:

  //media
  --ls-app-head-pg: 12px 0;
  --ls-app-head-bg: transparent;
  --ls-app-body-height: calc(100% - (122px + 10vh));

  //images:
}

//

.lg({
  :root {
    //fonts:

    //pages:

    //position:

    //media

    //images:
  }
});

.md({
  :root {
    //fonts:

    //pages:

    //position:

    //media
    --ls-app-head-pg: 15px 0;
    --ls-app-head-bg: #871628;
    //--ls-app-head-bg: @thin-color;
    --ls-app-body-height: calc(100% - 73px);

    //images:
  }
});

.sm({
  :root {
    //fonts:

    //pages:

    //position:

    //media


    //images:
  }
});

.xs({
  :root {
    //fonts:

    //pages:

    //position:

    //media

    //images:
  }
});

.theme(@end:{}) {
  .general(@brand-color, @accent-1-color) {
    --ls-brand-color: @brand-color;
    --ls-accent-1-color: @accent-1-color;
  }
  .backgrounds(@bg-global-color) {
    --ls-bg-global-color: @bg-global-color;
  }
  .colors(@thin-color, @meta-color, @extra-color, @between-color, @ultra-color, @alfa-color, @beta-color) {
    --ls-thin-color: @thin-color;
    --ls-meta-color: @meta-color;
    --ls-extra-color: @extra-color;
    --ls-between-color: @between-color;
    --ls-ultra-color: @ultra-color;
    --ls-alfa-color: @alfa-color;
    --ls-beta-color: @beta-color;
  }
  @end();
}
