.coming-soon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  color: #fff !important;

  * {
    color: #fff !important;
  }

  .md(min, {
    margin-bottom: 1rem;
  });

  .text-container {
    padding: 1rem;
    //padding: 3rem 2rem;
    //width: calc(400px - 6rem);
    //max-width: calc(100% - 2rem);
    color: white;
    font-size: 14px;
    line-height: 19px;
    z-index: 1;
    text-align: center;

    li {
      margin-bottom: 0.75rem;
    }

    .megaphone {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-bottom: 2rem;
    }

    .sm({
      padding: 1rem 1rem;
    });

    p {
      margin-bottom: 0px;
    }
  }
}
