.help__contacts {
  display: flex;

  .contact-item {
    .font(#0841DC 12px 15px);
    display: flex;
    align-items: center;
    margin-right: 2rem;
    img {
      width: 30px;
      height: 30px;
      margin-right: .5rem;
    }
  }
}

.help__container {
  display: flex;
  .font(#fff 12px 16px);
  align-items: center;
  cursor: pointer;

  .help__icon {
    border-radius: 50%;
    object-fit: contain;
    object-position: center;
    margin-right: 6px;
  }
}

.help {
  a, h5 {
    .font(#303440 15px 19px);
    font-weight: bold;
    margin: 20px 0 8px;
  }

  .text-container {
    h4, h5 {
      margin: 2rem auto 1rem auto;
    }

    p {
      margin-bottom: 1rem;
    }
  }

  table {
    td, th {
      .font(@white 14px 17px);
      border: solid 1px rgba(0, 0, 0, 0.1);
      padding: .1rem .5rem;
    }
  }
}

.help__question-container {
  .font(#fff 15px 21px);

  .help__answer {
    font-size: 15px;
    line-height: 19px;
    color: #A9A9A9;
  }
}

.help__contacts-container {
  margin-top: 2.25rem;

  h5 {
    .font(#0D2C90 18px 23px);
    margin-bottom: 10px;
  }

  .help__contacts {
    display: flex;

    .contact-item {
      .font(#0841DC 15px 19px);
      display: flex;
      align-items: center;
      margin-right: 2rem;
      img {
        margin-right: .5rem;
      }
    }
  }
}
