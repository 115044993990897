.app-profile {
  font-size: 20px;
  min-height: 100vh;
  display: flex;
}

.app-profile__container {
  max-width: 1200px;
  width: 100%;
  margin: auto;
  background-color: fade(@white-lighter, 50%);
  padding: 30px;
  border-radius: 30px;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

}

.app-profile__card {
  display: flex;
  flex-direction: column;

  a {
    margin-top: auto;
    div {
      text-align: center;
    }
  }
}

.app-profile__avatar {
  width: 300px;
  height: 250px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  background-color: @between-color;
  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
}

.app-profile__name {
  .font(@brand-color 30px 1 600);
  margin: 15px 0;
}

.app-profile__local {
  cursor: pointer;

  margin-bottom: 20px;
}

.app-profile__data-row {
  .font(@beta-color 18px 1);
  background-color: @white-lighter;
  margin-bottom: 15px;
  padding: 10px 15px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 10px;

  label {
    display: block;
    margin-bottom: 10px;
  }
}

.app-profile__data{
  user-select: text;
}

@import "profile-user";
