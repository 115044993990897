.feedback {
  width: 636px;
  box-shadow: 0 4px 4px rgba(@black-darker, 25%);
  margin: 30px 0;
  position: relative;
  max-width: 100%;
  border: 0;
  background-color: #FAEFE7;

  .animate({
    .action("fade-in");
  });

  //input {
  //  border: 0;
  //  padding: 0 29px;
  //  line-height: 50px;
  //}

  label {

    //.last({
    //  left: 29px;
    //  top: 16px;
    //});
  }

  textarea {
    .font(@ultra-color @font-base 19px);
    box-sizing: border-box;
    min-height: 116px;
    max-height: 116px;
    resize: none;
    max-width: 100%;
    width: 100%;
  }

  .input--primary {
    margin-bottom: 30px;
  }

  .button--primary {
    line-height: 50px;
    width: 100%;
    &:not(.active) {
      filter: grayscale(100%);
    }
  }
}

.feedback__data {
  max-width: 331px;
  margin: 42px auto;
  padding: 0 15px;

  > [class$="primary "] {
    .last(not, {
      margin-bottom: 36px;
    })
  }
}
