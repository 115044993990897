.talent {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  .app__block-head {
    padding: 12px 0;
  }
}

.talent__main {
  padding: 20px 30px 40px;

  .md({
    max-height: 428px;
  });

  h4 {
    .font(@alfa-color 24px 32px);
    margin-bottom: 0.75rem;
  }

  p {
    .font(@alfa-color 13px 16px);
    margin-bottom: 21px;
  }
}

.grid__main {
}

.talent__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(211px, 1fr));
  grid-gap: 20px 6px;
  //padding: 0 7px;

  .md({
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  });

  > svg {
    width: 100%;
    height: 150px;
  }
}

.talent--popup {
  p {
    margin-bottom: 20px;
  }
}
