.header {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  .sm({
    overflow: hidden;
    padding: .5rem 0;
  })
}

.header__title {
  .md(min, {
    min-width: 202px;
    //min-width: ~"min(202px, 100%)";
    padding: 3px;
    box-sizing: border-box;
  });

  img.action-image {
    width: 200px;
    position: absolute;
    max-width: 100vw;
    max-height: 100vh;
    left: 15px;
    //right: 15px;
    bottom: calc(100% - 38px);
    //bottom: calc(100% + 52px);
    //transform: translate(-10%, 40%);
    z-index: 1;
    //z-index: -1;
    cursor: pointer;

    .md({
      position: relative;
      height: 50px;
      width: auto;
      margin-top: 1rem;
      max-width: 100%;
      max-height: 100%;
      transform: none;
    })
  }

  img.logo-image {
    //width: 724px;
    //height: 235px;
    width: 135px;
    cursor: pointer;
    //height: 140px;
    position: absolute;
    max-width: 100vw;
    max-height: 100vh;
    left: 15px;
    bottom: calc(100% + 35px);
    //transform: translate(-10%, 40%);
    z-index: 10;

    .md({
      position: relative;
      height: 65px;
      width: auto;
      max-width: 100%;
      max-height: 100%;
      transform: none;
    })
  }
}

.header__main {
  display: flex;
  align-items: center;
  flex: 1 1 auto;

  .md({
    .mobileNavigation();
  });
}


.header__part {
  width: 100%;
  max-width: 458px !important;
  position: absolute;
  //left: 0;
  overflow: hidden;
  left: calc(14.7rem);
  bottom: calc(100%);

  span {
    .font(@alfa-color 12px 14px);
  }

  h1 {
    font-size: 36px;
    line-height: 30px;
    color: @alfa-color;
    margin: 20px 0;
    font-weight: normal;
    animation: sliding 60s linear infinite;
    white-space: nowrap;
    width: max-content;
  }

  div{
    display: inline-block;
    .font(#fff 12px 17px);
    background-color: @brand-color;
    padding: 0 10px;
    border-radius: 4px;
  }
}


@keyframes sliding {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
