.live-chat {
  width: 202px;

  .sm({
    min-height: 347px;
    width: 100%;
    height: 100%;
  });


  .sm(min, {
    height: 100%;
  })
}

.live-chat__viewport {
  padding-bottom: 0;
  box-sizing: border-box;
  height: calc(100% - 69px);
}

.live-chat__control {
  position: relative;
  padding: 5px;
  box-sizing: border-box;
  //height: 106px;
}

.live-chat__input {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 15px 4px 15px;

  &-chat {
    position: relative;
    overflow: hidden;
    //height: 96px;
    flex: 1 1 auto;

    label {
      .font(#828282 12px 1);
      position: absolute;
      top: 1px;
      left: 1px;
      pointer-events: none;
      transition: @transition;

      &.active{
        transform: translateY(-100%);
        opacity: 0;
      }
    }

    textarea {
      .font(#878A8D @font-base 16px);
      border-bottom: 1px solid #878A8D;
      background-color: transparent;
      height: 20px;
      min-height: 20px;
      min-width: 190px;
      max-width: 190px;
      max-height: 90px;

      span {
        vertical-align: middle;
      }
    }

    .textarea {
      .font(@alfa-color @font-base 16px);
      border-bottom: 1px solid @alfa-color;
      background-color: transparent;
      outline: none;
      max-width: calc(100% - 12px);
      min-height: 20px;

      span {
        vertical-align: top;
      }

      &.active, &:focus {
        ~ label {
          transform: translateY(-100%);
          opacity: 0;
        }
      }
    }
  }

  &-submit, &-emoji {
    &:hover {
      filter: drop-shadow(0 0 0 @brand-color);
    }
  }

  &-submit {
    margin: 0 6px;
  }

  &-emoji {
    cursor: pointer;

  }

  .emoji-mart {
    position: absolute;
    width: calc(100% + 2px) !important;
    bottom: 100%;
    left: 0;
    border: 1px solid var(--ls-meta-color);
    z-index: 1;
    margin: 0 -1px;

    &-bar {
      display: none;
    }
  }

}

.live-chat__message {
  position: relative;
  padding: 7px 9px;
  clear: both;
  color: #303440;


  .animate({
    .action("fade-in");
  });

  &-avatar {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    //background-color: @white-lighter;
    float: left;
    overflow: hidden;
    margin-bottom: 10px;
    margin-right: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
      width: 23px;
      height: 21px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &-username, &-factory {
    margin-left: 34px;
  }

  &-username {
    .font(#303440 @font-small 14px, {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    });
    width: calc(100% - 60px);
    margin-bottom: 3px;
  }

  &-factory{
    .font(#303440 10px 14px, {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    });
    width: calc(100% - 45px);
  }

  &-text {
    .font(#303440 @font-small 17px, {
      word-break: break-word;
    });
    margin-right: 15px;
    user-select: text;
    clear: both;

    span{
      vertical-align: top;
    }

    &::selection {
      background-color: @brand-color;
      color: @thin-color
    }
  }

  &-time {
    .font(#828282 8px 11px, {
      font-family: @brand-family-2;
    });
    position: absolute;
    top: 8px;
    right: 11px;
  }
}

.live-chat__user {
  display: flex;
  align-items: center;

  &-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    //background-color: @between-color;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &-name {
    .font(@brand-color @font-large 16px, {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    });
    width: ~"min(160px, 100%)";
    margin-left: 8px;
  }
}
