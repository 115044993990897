.shedules {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  color: rgb(8, 8, 8) !important;

  * {
    color: rgb(7, 7, 7) !important;
  }

  h5 {
    font-size: 18px;
    line-height: 23px;
    color: #303440;
  }

  .md(min, {
    margin-bottom: 1rem;
  });

  .text-container {
    padding: 1rem;
    //padding: 3rem 2rem;
    //width: calc(400px - 6rem);
    //max-width: calc(100% - 2rem);
    color: rgb(12, 11, 11);
    font-size: 14px;
    line-height: 19px;
    z-index: 1;
    text-align: center;

    li {
      margin-bottom: 0.75rem;
    }

    .megaphone {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-bottom: 2rem;
    }

    .sm({
      padding: 1rem 1rem;
    });

    h5 {
      font-size: 18px;
      line-height: 23px;
      color: #303440;
    }

    p {
      margin-bottom: 0px;
    }
  }
}

.shedules_text {
  margin: 0 20px;
  padding: 0px;

  h5 {
    font-size: 18px;
    line-height: 23px;
    color: #303440;
  }

  h4 {
    font-size: 18px;
    line-height: 23px;
    color: #303440;
  }

  p {
    font-size: 12px;
    line-height: 130%;
    color: #303440;
  }

  img {
    width: 272px;
    height: 167px;
    margin-right: 0rem;
  }
  a {
    font-size: 12px;
    line-height: 130%;
    color: #303440;
  }
}
