.icon--message {
  width: 20px;
  height: 21px;
  background: url("../../../icons/message.png") center / contain no-repeat;
}

.icon--emoji {
  width: 14px;
  height: 21px;
  background: url("../../../icons/emoji.png") center / contain no-repeat;
}

.icon--feedback {
  width: 24px;
  height: 25px;
  background: url("../../../icons/feedback.png") center / contain no-repeat;
}

.icon--like {
  width: 16px;
  height: 16px;
  background: url("../../../icons/like.png") center / contain no-repeat;
}
