.screen {
  position: relative;

  &.defined {
    background: transparent;
  }

  .last(not, {
    margin-bottom: 12px;
  });

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-mask-image: ~"-webkit-radial-gradient(white, black)";
    border-radius: 6px;
  }

  .md({
    height: 228px;
    order: 0;
  });

  .md(min, {

  });
}

.screen__title {
  .flex.center();
  .font(@brand-color @h-font-base 87px);
  background-color: #2D191C9E;
  max-height: 100%;
  //height: 100%;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.screen__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 25px;
  margin: 25px;

  .md({
    display: block;
    max-height: 607px;
  })
}

.screen__item {
  .md({
    margin-bottom: 25px;
  })
}

.screen__item-link {
  height: 100%;
}

.screen__item-head {
  height: 168px;
  position: relative;

  + .screen__item-body {
    margin-top: 7px;
  }

  a {
    display: block;
    height: 100%;
    background-color: @meta-color;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  //span{
  //  position: absolute;
  //  top: 10px;
  //  right: 10px;
  //  width: 60px;
  //  height: 60px;
  //  svg{
  //    width: inherit;
  //    height: inherit;
  //  }
  //}
}

.screen__item-body {
  .font(@between-color @font-base 19px);
  position: relative;
  user-select: text;
}

.screen__preview {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  pointer-events: none;
}

.screen__item-summary {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: calc(100% - 60px);
  word-break: break-word;
}

.screen__item-like {
  //position: absolute;
  //top: 0;
  //right: 0;
  //display: flex;
  //align-items: center;
  //cursor: pointer;
  //z-index: 1;
  //
  //
  //&:active {
  //  div {
  //    .animate({
  //      .action("scaleToIn", @duration: .3s);
  //    });
  //  }
  //}
  //
  //div {
  //  margin-right: 6px;
  //}
  .font(#333 12px 24px);
  position: absolute;
  background-color: #FAEFE7;
  border-radius: 4px;
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  cursor: pointer;
  left: 6px;
  bottom: 6px;
  padding: 0 11px 0 4px;

  span {
    color: @brand-color;
    margin: 0 6px 0 3px;
  }

  &.active {
    span {
      color: #A9A9A9
    }
    svg {
      path {
        fill: #A9A9A9;
      }
    }
  }
}
