.leaders_block {
  height: 50%;
  margin-top: 1rem;

  h4 {
    .font(@ultra-color 13px 16px);
    text-transform: uppercase;
    padding: 0 1rem;
  }
}

.leader__item {
  display: grid;
  margin: 1rem auto;
  grid-template-rows: repeat(10, 30px);
  grid-auto-flow: column;
  grid-auto-columns: 200px 220px;

  //.xs(min, {
  //  grid-template-columns: 1fr 1fr 3fr 2fr;
  //});
  align-items: center;
  grid-row-gap: 5px;
  justify-content: space-between;
  padding: 0 1rem;
  .font(#878A8D 12px);

  .leader__item-name {
    .md(min, {
    });
  }

  .leader__item-score {
    .font(@alfa-color 12px);
    white-space: nowrap;
    margin-left: auto;
    text-align: right;
  }

  .leader__item-avatar {
    margin: auto .5rem;
    width: 20px;
    height: 20px;
    border-radius: 50%;

    img {
      min-width: 100%;
      min-height: 100%;
      border-radius: 50%;
    }
  }
}

.leaders_sorting_container {
  --color: @ultra-color;
  .font(var(--color) 12px 16px);
  border-bottom: 1px dashed var(--color);
  margin: auto 1rem;
  padding-bottom: 4px;
  cursor: pointer;

  .sorting-name {
    margin-right: .5rem;
  }

  &.hover {
    --color: #fff;
  }
}
