@store: {
  .animate({
    .is('scaleToIn', {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    });
    .is('rotate', {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    });
    .is('dash', {
      0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
      }
    });
    .is("slideLeftIn", {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
    });
    .is("slideLeftOut", {
      0% {
        transform: translateX(0);
        visibility: visible;
        opacity: 0;
      }
      100% {
        transform: translateX(-100%);
      }
    });
    .is("fade-in", {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    });
    .is("ripple", {
      0% {
        opacity: .4;
        transform: translate(-50%, -50%) scale(0);
      }
      50% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
      }
      100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
      }
    });
  });
}

.animate({
  .model(
    @types:
    "fade-in"
    "rotate"
    "scaleToIn"
    "dash",
    @store: @store
  );
});
