.custom-title {
  .font(@thin-color @font-base 19px, {
    text-align: center;
    word-break: break-word;
  });
  background-color: @brand-color;
  border-radius: 15px;
  padding: 15px;
  border: 1px solid @thin-color;
  min-height: 51px;
  box-sizing: border-box;

  .md({
    font-size: @h-font-small;
  });

  .last(not, {
    margin-bottom: 25px;
  })
}
