.qviz {
  position: relative;
}

.qviz, .qviz__finish {
  background-color: #FAEFE7;
  border-radius: 6px;
  height: 100%;
  padding: 26px 28px;
  box-sizing: border-box;
}


.qviz__finish {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  h2 {
    .font(#333 18px 21.33px, {
      font-family: @brand-family-2;
    })
  }
  p {
    .font(#333 15px 17.78px);
    margin: 10px 0 22px;
  }

}

.qviz__count {
  .font(@brand-color 12px 14.22px, {
    font-family: @brand-family-2;
  });
  margin-bottom: 11px;
}

.qviz__question {
  .font(#333 18px 21.33px, {
    font-family: @brand-family-2;
  });
}

.qviz__answers {
  display: flex;
  flex-direction: column;
  padding-top: 19px;
  width: 100%;
}

.qviz__answer {
  margin-bottom: 13px;

  &.active {
    label {
      border-color: #F96E09;
    }
  }

  &.error {
    span {
      background: url('../../../../../images/shared/error.svg') center / auto no-repeat #EB5757;
    }
  }
  &.success {
    span {
      background: url('../../../../../images/shared/success.svg') center / auto no-repeat #219653;
    }
  }
}

.qviz__popover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffd1;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  .font(#333 18px 21.33px, {
    font-family: @brand-family-2;
  });

  animation: fade-in 1s ease;

  p {
    max-width: 302px;
    margin: auto;
    text-align: center;
  }

  span {
    background: url('../../../../../images/shared/force.png') center / auto no-repeat;
    width: 18px;
    height: 18px;
    display: inline-block;
    margin: 0 -14px 0 4px;
  }
}

.class-for-vlad {
  height: 100px;
  margin-top: 10px;
}

.qviz__bur {
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  padding: 13px 11px;
}


.qviz__bur-item {
  margin-bottom: 7px;
}

.qviz__bur-thumb {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: @white-lighter;
  float: left;
  overflow: hidden;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 23px;
    height: 21px;
    margin-left: 2px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.qviz__bur-data {
  .font(#333 10px 1.3);
  margin-left: 33px;
  margin-bottom: 1px;
}

.qviz__bur-q {
  .font(#828282 12px 1.3);
  letter-spacing: -0.02em;
  margin-left: 33px;

  span {
    display: inline-block;
    width: 14px;
    height: 14px;
    vertical-align: text-bottom;
    margin-left: 7px;
    background: url('../../../../../images/shared/uncheck.png') center /auto no-repeat;
    &.active {
      background: url('../../../../../images/shared/check.png') center /auto no-repeat;
    }
  }
}

.result{
  padding: 7px 20px;
  strong{
    .font(#333 12px 1.3);
    margin-right: 8px;
  }

  span{
    .font(#333 12px 1.3);
  }
}
