.cell-time{
  .font(@beta-color 18px 1);
  border: 1px solid @between-color;
  padding: 10px;
  transition: @transition;
  cursor: pointer;
  background-color: @meta-color;

  &:hover, &.active{
    background-color: @between-color;
    color: @thin-color
  }

  &.active{
    opacity: .8;
  }
}
