.custom-portal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-y: auto;
  z-index: 5;
  //backdrop-filter: blur(5px);
  //background-color: fade(@white-lighter, 80%);
  background: rgba(0, 0, 0, 0.8);
  background-size: cover;

  &:before {
    content: ' ';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
}
