.grid {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  min-height: 412px;
  box-sizing: border-box;
  margin: 0 0 10px 0;
  .app__block-head {
    padding: 12px 0;
  }
}

.grid__main {
  padding: 20px 30px;

  .md({
    max-height: 428px;
  });

  .text {
    font-size: 12px;
    line-height: 130%;
    letter-spacing: -0.01em;
    color: #a9a9a9;
    margin-top: 10px;
    margin-bottom: 21px;
  }

  h5 {
    .font(@alfa-color 14px 17px);
    text-transform: uppercase;
    margin: 20px auto;
  }

  .grid__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 8px;

    .sm({
      grid-template-columns: 1fr;
    });
  }
}

.grid__textarea {
  textarea {
    min-height: 50px;
    .font(@alfa-color 15px 19px);

    ~ label {
      line-height: 17.78px !important;
    }
  }
}

.new-comment-container {
  .button--primary {
    padding: 12px 42px 15px 42px;
    .font(#fff 15px 17px);
    text-transform: uppercase;
    margin: 15px auto;
  }
}
