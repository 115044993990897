.activities-container {
  padding: 20px 30px 40px;
  h4.headline {
    .font(#0D2C90 24px 130%);
    margin-bottom: 20px;
  }

  h5.title {
    .font(@alfa-color 14px 18px);
    margin: 12px auto;
    text-transform: uppercase;
    font-weight: bold;
  }

  .rules {
    .font(@alfa-color 13px 17px);
    .rule {
      list-style: initial !important;
      margin-left: 1rem;
    }
  }

  .main-text {
    font-size: 18px;
    line-height: 23px;
    color: #0D2C90;
    margin: 20px 0 12px;
  }

  .secondary-text {
    font-size: 12px;
    line-height: 130%;
    color: #303440;
  }

  ul {
    list-style: inside;
  }

  li {
    margin: 6px 0;
  }
}
.games-holder {
  background: rgba(135, 138, 141, 0.1);
  padding: 20px 30px 40px;
  .games-day-holder {
    margin-bottom: 40px;
  }
}

.games-container {
  .font(white 15px 17px);

  .games-title {
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: bold;

    .enable-date {
      background: white;
      border-radius: 40px;
      margin-left: 8px;
      color: @brand-color;
      padding: 4px 6px;
    }
  }

  &.disabled {
    .games-day, .games-list {
      opacity: 0.2;
    }
    .enable-date {
      opacity: 1 !important;
    }
  }

  .games-list {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;

    //.sm({
    //  grid-template-columns: 1fr;
    //})
  }
}

.game-container {
  color: white !important;
  cursor: pointer;
  .game-thumbnail-holder {
    .game-image {
      border-radius: 10px;
      width: 100%;
      margin-bottom: 8px;
    }
  }

  &.disabled {
    opacity: 0.2;
  }
}

.portal__container.game {
  //max-height: calc(100vh - 60px);
  padding: 0;

  .game-window {
    width: 100%;
    height: 100%;
  }

  .portal__data {
    height: 100%;
  }
}

.activity-test-container {
  padding: 1rem 1rem;
}

.activity-video-container {
  position: relative;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
