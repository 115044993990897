.questionary_container {
  padding: 40px 75px 60px;

  .sm({
    padding: 40px 35px 60px;
  });

  .questionary_header {
    .font(@ultra-color 13px 17px);
    display: flex;
    align-items: center;
    .link {
      cursor: pointer;
      img {
        height: 13px;
        margin-right: .25rem;
      }
    }
    margin-bottom: 30px;
  }
}
.questionary-container {
  h5 {
    .font(#0D2C90 24px 31px);
    margin-bottom: 20px;
  }
  p {
    .font(#303440 12px 16px);
    margin-bottom: 18px;
  }
  h4 {
    margin-bottom: 20px;
  }
  .questions {
    margin-bottom: 50px;
  }
  .questions-submit-button {
    background: #0841DC;
    border-radius: 4px;
    .font(#fff 13px 17px);
    text-transform: uppercase;
    padding: 12px 20px;
    min-width: 240px;
    min-height: 40px;
  }
  .question-container {
    margin: 40px auto;
  }
  .question-answer-text {
    border-radius: 54px;
    border: 1px solid #ADB5C5;
    margin: 12px auto;
    padding: 8px 14px;
    .toggle--primary label {
      font-size: 15px !important;
      line-height: 19px !important;
    }
  }
}
.result-container {
  .result-text {
    .font(#878A8D 16px 20px);
    margin: 40px auto 6px;
  }
  .result-suggestions {
    .font(@alfa-color 15px 21px);
  }
  h4 {
    margin-bottom: 1rem;
  }
  .result-description {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 20px;
    color: #878A8D;
  }
  li {
    list-style: initial !important;
    margin-left: 1rem;
    &::marker {
      font-size: 12px;
    }
  }
  .result-buttons {
    margin-top: 34px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .sm({
      grid-template-columns: 1fr;
    });
    align-items: center;
    .result-return, .result-rerun {
      text-transform: uppercase;
      .font(#fff 14px 17px);
      padding: 12px 35px;
      border-radius: 4px;
    }
    .result-return {
      background: #0841DC;
    }
    .result-rerun {
      background: transparent;
    }
  }
}
.test-result {
  margin: 1rem auto;
}

.test-recommendation-button {
  .font(white 13px 17px);
  font-weight: bold;
}
