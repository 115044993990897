.detective-container {
  .detective-container_main-text {
    font-size: 24px;
    line-height: 130%;
    color: #0D2C90;
  }

  .detective-container_description {
    font-size: 15px;
    line-height: 19px;
    color: #303440;
    margin-top: 10px;
  }

  .detective-container_description-block {
    background: #F0F2F5;
    padding: 14px;
    margin: 30px 0;

    .detective-container_name {
      font-weight: bold;
      font-size: 14px;
      line-height: 130%;
      color: #303440
    }

    .detective-container_title {
      font-size: 15px;
      line-height: 19px;
      color: #878A8D;
    }

    .detective-container_description {
      font-size: 15px;
      line-height: 19px;
      color: #303440;
    }
  }

  .detective-container_quiz-text {
    font-size: 18px;
    line-height: 23px;
    color: #0D2C90;
    margin-bottom: 20px
  }

  .detective-container_person-container {
    display: flex;
    flex-wrap: wrap;



    .detective-container_person-block:nth-child(even){
      margin-left: 19px;
    }

    .detective-container_person-block {
      width: 308px;
      margin-bottom: 30px;

      .detective-container_image {
        margin-bottom: 15px;
      }
    }
  }

  .detective-container_button {
    width: 100%;
    margin-top: 40px;
  }

  .detective-container_numerical {
    font-size: 15px;
    line-height: 19px;
    color: #303440;
  }

  .detective-container_time-table {
    display: flex;
    flex-wrap: wrap;

    .detective-container_time-item:nth-child(even){
      margin-left: 50px;
    }

    .detective-container_time-item {
      max-width: 280px;

      p, div {
        margin-top: 30px;
      }
    }
  }


  .detective-container_four_table {

    .detective-container_four_table-row {
      display: flex;
    }

    .detective-container_four_table-item {
      width: 58px;
      height: 37px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #303440;
      justify-content: center;
    }
  }

  .detective-container_img-round {
    width: 52px;
    height: 52px;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 1000px;

  }
}


