.portal {
  display: inline-block;
  vertical-align: middle;
  max-width: calc(100% - 15px);

  .local {
    font-size: @font-base;
  }
}

.portal__container {
  padding: 40px 30px;
  //border-radius: 6px;
  max-width: calc(100% - 30px);
  width: 390px;
  box-sizing: border-box;
  text-align: center;
  margin: 30px 15px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  //border: 1px solid #7492FF;
  border-radius: 10px;

  .md({
    padding: 30px;
  });

  .animate({
    .action('fade-in');
  });

  .local__item {
    cursor: pointer;
  }
}

.portal__data {
  text-align: left;
  background: #fff;
  h4 {
    .font(#0d2c90 24px 27px, {
      font-family: @brand-family-3;
    });
    font-weight: bold;
  }

  > a {
    .font(@beta-color @font-less 14px);
    .last({
      &:before {
        content: '|';
        margin: 0 10px;
        color: fade(@black-darker, 20%);
      }
    });
    &:hover {
      color: @brand-color;
    }
  }

  p {
    .font(#0d2c90 @font-base 16px);
    /* Все статьи */
  }

  h4 {
    margin-bottom: 12px;
  }

  > div {
    .last(not, {
      margin-bottom: 12px;
    });
  }

  .watch-live {
    margin-bottom: 0.5rem;
    text-align: center;
    a {
      .font(white 14px 17px);
      .text-underscore();
    }
  }

  .auth-help {
    display: flex;
    justify-content: flex-end;
  }
}

.portal__close {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -30%);
  z-index: 1;
}

.portal__container--error {
  width: ~'min(400px, 100%)';
  max-width: 100%;
}

.portal__container {
  &.profile {
    width: 682px;
    //padding-top: 20px;
    padding: 0;
  }
}
.portal__data-container {
  padding: 30px 75px 50px 75px;
  .sm({
    padding: 30px 35px 50px 35px;
  });
}

.portal__container.help {
  padding: 30px 75px 50px 75px !important;
  .sm({
    padding: 30px 35px 50px 35px !important;
  });
}

.portal__data-row {
  //display: flex;
  align-items: flex-start;
  //margin: 0 -15px;

  .xs({
    flex-wrap: wrap;
  });
}

.portal__pdf {
  display: flex;
  border-top: 1px solid #e0d9d3;
  border-bottom: 1px solid #e0d9d3;
  padding: 24px 0 20px;
  margin-top: -20px;
}

.portal__pdf-thumb {
  background-color: #fff;
  border-radius: 50%;
  .font(@brand-color 15px 17.78px);
  width: 52px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  margin-right: 11px;
}

.datepicker * {
  background-color: #fff;
  color: #000000;
  font-weight: 400;
  input {
    border: 1px solid #0841dc;
    padding: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    color: #303440;
  }
}

.portal__pdf-data {
  max-width: 178px;
  a {
    .font(@brand-color 15px 17.78px);
  }
  span {
    .font(#828282 12px 15.6px);
    letter-spacing: -0.02em;
    display: block;
    margin-top: 6px;
  }
}

.portal__data-col {
  flex: auto;
  //margin: 0 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 3rem;
  .sm({
    grid-template-columns: 1fr;
  });

  > div {
    margin-bottom: 23px;
  }
}

.w-100 {
  width: 100%;
}

.logout-link {
  .font(#0841dc 12px 16px);
  padding-bottom: 0.3rem;
  cursor: pointer;
}

.text-underscore {
  border-bottom: 1px solid @brand-color;
}
.text-white {
  color: #fff !important;
}
.text-black {
  color: black;
  font-weight: bold;
}

.text-blue {
  color: #0841dc;
}

.text-sm {
  font-size: 12px;
  line-height: 16px;
}
.my-3 {
  margin-top: 1rem;
}
.font-bold {
  font-weight: bold;
}

.width100 {
  width: 100%;
}
