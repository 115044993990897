@import '~simplebar/dist/simplebar.min.css';
@import '~swiper/swiper.less';
@import './general/xnls';
@CASE_LG: 916px;
@import './general/index';
@import './components/ui/index';
@import './components/templates/index';
@import 'components/modals/index';
@import 'components/pages/index';
@import './general/typography';
