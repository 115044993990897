.custom-player {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .player-wrapper {
    width: 100%;
  }

  .react-player__preview{
    &:focus{
      outline: none;
    }
  }

}

.custom-player--effect {
  background-color: @brand-color;

  .player-wrapper {
    transition: @transition;
  }

  &:hover {
    .player-wrapper {
      transform: translate(8px, -8px);
    }
  }
}
