.navigation {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .md(min, {
    margin-left: 15px;
  });

  .md({
    width: 100%;
    flex: 1 1 auto;
    overflow-y: scroll;
    max-height: calc(100% - 100px);
    padding: 10px;
  });
}

.navigation__item {
  width: 148px;
  margin-right: 10px;

  .md({
    margin-bottom: 30px;
  });
}

.navigation__link {
  .font(#0D2C90 15px 19px, {
    text-align: center;
    white-space: nowrap;
    font-family: @brand-family-2;
  });

  display: block;
  min-width: 105px;
  width: auto;
  padding: 8px;
  box-sizing: border-box;
  //border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 2px 1px 2px fade(@black-darker, 25%);
  margin: 4px;
  border-radius: 6px;


  &:hover, &.active {
    background-color: @brand-color;
    color: #fff;
  }

  &.active{
    pointer-events: none;
  }
}
