.description {
  padding: 15px;
  background-color: @extra-color;
  width: 300px;
  box-sizing: border-box;
  display: inline-block;
  border: 1px solid @brand-color;

  .local{
    margin-bottom: 20px;
    cursor: pointer;
  }
}

.description__head {
  > div {
    &:last-child {
      margin-top: 8px;
    }
  }
}

.description__date {
  .font(@beta-color 11px 1);

  margin: 15px 0;

  span {
    .font(@beta-color 3vmax 1 600);
    margin-right: 3px;
  }
}

.description__body {
  margin: 15px 0;
  textarea {
    height: 160px;
    min-width: 100%;
    max-width: 100%;
    max-height: 260px;
    padding: 15px;
    box-sizing: border-box;
  }
}

.description__foot {
  text-align: center;
}
