.review-component {
  min-height: 14rem;
  height: max-content !important;
  p {
    .font(white 14px 17px);
  }

  .rc-title {
    padding: 10px 20px;
  }

  .review-input-holder {
    margin: 1rem auto;
  }

  .button--primary {
    min-width: 160px;
    line-height: 30px ;
  }
}