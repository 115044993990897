.quiz-controller {
  padding: 0 30px;
  min-height: 15rem;
  h5 {
    .font(white 15px 19px);
    margin-bottom: 21px;
  }

  .quest-city-container {
    h5 {
      margin-bottom: 9px;
      color: @ultra-color !important;
    }
  }

  .quiz-questions-container {
    margin: -30px auto;
  }

  .cancel-button {
    margin: .5rem auto;
  }

  .quest-question {
    .font(white 14px 17px);
    margin: 40px auto;

    .quest-question-image {
      width: 100%;
      border-radius: 4px;
      margin: 10px auto;
    }

    .quest-question-answers {
      margin: 12px auto;

      .quest-question-answer {
        background: #fff;
        border: 1px solid #ADB5C5;
        margin: 12px auto;
        border-radius: 54px;
        padding: 8px 15px;
      }
    }
  }
}

