.controls {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-width: 202px;
  justify-content: space-between;

  .md(min, {
    margin-left: 5px;
    //margin-left: auto;
  });

  .md({
    flex: 1 1 auto;
    justify-content: center;
  });
  //
  //a {
  //  .font(@brand-color @font-base 19px 600, {
  //    text-align: right;
  //  });
  //  padding: 0 5px;
  //  display: block;
  //}
}

.controls__item {
  &:first-child{
    min-width: 101px;
  }
  > span{
    color: @brand-color;
    font-size: 10px;
    line-height: 1;
    font-family: @brand-family-3;
    display: block;
  }

  a{
    line-height: 14px;
    margin: 4px 0;
    display: inline-block;
  }

  a, .button--label{
    color: @brand-color;
    font-size: 12px;
    font-family: @brand-family-2;
  }

  .button--label{
    line-height: 20px;
    padding: 0 7px;
    border: 0;
    cursor: pointer;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    font-size: 12px;

    &:hover{
      background-color: @brand-color;
      color: #fff;
    }
    div{
      display: none;
    }
  }


  //.font(@brand-color @font-base 25px);
  //cursor: pointer;
  //
  //.first({
  //  display: flex;
  //  align-items: center;
  //  justify-content: flex-end;
  //  width: 100%;
  //  margin-bottom: 5px;
  //  text-align: right;
  //
  //  .md({
  //    justify-content: center;
  //  });
  //});
  //
  //&:not(:first-child):not(:last-child) {
  //  margin-right: 15px;
  //}
}
