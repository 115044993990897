.lot {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  .app__block-head {
    padding: 12px 0;
  }
  padding: 20px 30px 30px;
}

.lot__main {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 24px;
    line-height: 130%;
    color: #0D2C90;
  }
}

.lot__body {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  min-width: 660px;

  .card {
    border: 1px solid #F0F2F5;
    cursor: default;
    border-radius: 10px;
    width: 206px;
    margin-bottom: 27px;
    box-shadow: 0px 33px 38px rgba(0, 0, 0, 0.03), 0px 9.94853px 11.4559px rgba(0, 0, 0, 0.0195477), 0px 4.13211px 4.75819px rgba(0, 0, 0, 0.015), 0px 1.4945px 1.72094px rgba(0, 0, 0, 0.0104523);

    img {
      border-radius: 10px 10px 0 0;
    }

    .card__body {
      margin: 18px 12px 12px 12px;
    }

    .card__title {
      font-size: 15px;
      line-height: 19px;
      color: #303440;
      margin-top: 18px;
    }

    .card__price {
      font-size: 14px;
      line-height: 130%;
      color: #303440;
      margin-top: 12px;
      font-weight: bold;
    }

    .card__count {
      font-size: 15px;
      line-height: 19px;
      color: #A9A9A9;
      margin-top: 10px;
    }
  }
}

.lot__data {
  text-align: center;
  background: url('../../../../../../../images/shared/lot.jpg') center / cover no-repeat;
  padding: 8px 7px 22px;
}

.lot__title {
  color: #fff;
  font-size: 62px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  max-width: 90%;
  margin: 19px auto 20px;
  text-shadow: 5px 7px 1px #aa0202;

  .xs({
    font-size: 32px;
  });
}

.lot__desc {
  .font(#fff 18px 1);
  margin-bottom: 20px;
  text-shadow: 1px 2px 1px #aa0202;
}

.lot__box {

}

.lot__link {
  .font(#fff 25px 1);
  margin: 0 15px;
  text-shadow: 1px 2px 1px #aa0202;
  font-weight: 700;

  .xs({
    display: block;
    margin-bottom: 20px;
  });
}

.lot__to {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -20px 0;

  .xs({
    flex-direction: column;
    margin: auto;
  });

  img {
    max-width: 130px;
    margin: 0 21px;
  }
}

.lot__to-name {
  color: #fff;
  font-size: 21px;
  line-height: 1;
  text-shadow: 1px 2px 1px #aa0202;
}

.lot__case {
  width: 30px;
  height: 30px;
  background-color: #aa0202;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 50%;
  line-height: 30px;
  margin: 10px auto;
}

.lot__to-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 182px;
  height: 50px;
  font-size: 13px;
  margin-top: 18px;
  line-height: 17px;
  text-align: center;
  color: #0841DC;
  background: #F0F2F5;
  letter-spacing: 0.12em;
  cursor: pointer;
}
.lot__to-button-disabled {
  background: #F0F2F5;
  color: #A9A9A9;
  cursor: default;
}
