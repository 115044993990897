.city {
  height: ~"min(234px, 30%)";
}

.city__wrapper {
  //margin: 15px 0;
}

.city__item {
  padding: 8px 18px;
  .clear();
}

.city__item-name {
  .font(@beta-color @font-small 15px, {
    text-overflow: ellipsis;
    white-space: nowrap;
  });

  float: left;
  max-width: calc(100% - 50px);
  overflow: hidden;
}

.city__item-time {
  .font(@brand-color @font-small 15px);
  float: right;
}
