[class*=toggle--] {
  input {
    display: none;
  }
  label {
    display: inline-flex;
    align-items: flex-start;
    cursor: pointer;
  }
}

.toggle--primary {

  &.error {
    .toggle--primary__switch {
      border-color: @danger;
    }
  }

  input:checked {
    ~ .toggle--primary__switch {
      background-color: @brand-color;
    }
  }

  input:not(:checked) {
    transform: rotate(-90deg);
    ~ span {
      svg {
        opacity: 0;
      }
    }
  }

  label {
    .font(#A9A9A9 12px 1.3);
    text-align: left;
  }
}

.toggle--primary__switch {
  min-width: 16px;
  min-height: 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.toggle--test {

  &.error {
    .toggle--test__switch {
      border-color: @danger;
    }
  }

  input:checked {
    ~ .toggle--test__switch {
      background-color: #219653;
    }

    ~ .question-answer-text {
      border: 1px solid #0841DC;
    }
  }

  input:not(:checked) {
    transform: rotate(-90deg);
    ~ span {
      svg {
        opacity: 0;
      }
    }
  }

  label {
    .font(#303440 15px 1.3);
    text-align: left;
  }
}

.toggle--test__switch {
  min-width: 16px;
  min-height: 16px;
  border: 1px solid #A9A9A9;
  border-radius: 50%;
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.toggle--qviz {
  svg{
    display: none;
  }
  label {
    width: 100%;
    border-radius: 30px;
    background-color: #e1d7d0;
    .font(#333 15px 17.78px);
    padding: 7px 12px;
    display: flex;
    border: 1px solid transparent;
    align-items: center;
    box-sizing: border-box;

    &:hover{
      border-color: #F96E09;
    }
  }
}

.toggle--qviz__switch {
  border: 1px solid #F96E09;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 8px;
}

.toggle--check {
  input:checked {
    ~ svg {
    }
  }

  svg {
    min-width: 18px;
    height: 18px;
    border-radius: 4px;
    transition: fill .3s ease;
    margin-right: 8px;
    align-self: flex-start;
  }

  label {
    .font(#333 12px 1.3);
  }
}
