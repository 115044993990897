.reset();
//.mobileLayout('#root');
@import "../components/ui/preloader";

html:not([theme]) {
  .defaultTheme();
}

body {
  //background: linear-gradient(180deg, #70c8ec 21%, #fff 70%);
  .md({
    &.active {
      overflow: hidden;
    }
  });

  .md(min, {
    &.active {
      .app {
        filter: blur(10px);
      }
    }
  })
}

.container {
  .container();
}

.confirm--fix {
  padding-bottom: 15px;
  position: relative;

  a {
    position: absolute;
    bottom: 0;
    left: 26px;
    width: 100%;
    color: #0841DC !important;
  }
}

.confirm--note {
  color: @ultra-color;
  text-align: center;
}

.confirm--fix-to {
  margin: 1rem auto;
  a {
    color: #0841DC;
    text-decoration: underline;
    margin-left: 5px;
  }
}

.qustion{
  label{
    position: relative;
  }
  b{
    border-radius: 50%;
    background: #fff;
    color: @brand-color;
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    &:hover{
      span{
        display: block;
      }
    }
    span{
      text-align: left;
      z-index: 1;
      position: absolute;
      left: 0;
      top: calc(100% + 10px);
      background: #fff;
      padding: 10px;
      border-radius: 6px;
      right: 0;
      box-shadow: 0 1px 10px rgba(0,0,0,.2);
      display: none;
      line-height: 16px;
    }
  }
}


//.debug();

.text-center {
  text-align: center !important;
}

.text-in {
  font-size: 24px;
  color: #0D2C90;
}

.text-secondary {
  color: @ultra-color !important;
}

.side-note {
  .font(@alfa-color 12px 15px);

  a {
    color: white;
  }
}

.personnel-question {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background: white;
  border-radius: 50%;
  text-align: center;
  margin-left: 1rem;
}

.tooltip-body {
  position: relative;

  .tooltip-label {
    position: absolute;
    top: 0;
    left: 100%;
    //display: none;
    opacity: 0;
    transition: .5s linear all;
    background: white;
    min-width: 10rem;
    padding: .25rem 1rem;
    z-index: 2;
    border-radius: 1rem;
    color: black;
  }

  &:hover {
    .tooltip-label {
      opacity: 1;
      //display: initial;
    }
  }
}
i {
  font-style: italic;
}

.md-ml-3 {
  .md(min, {

  })
}

.button-wide {

}
