.custom-scroll {
  .flex(column);
  overflow: hidden;
  height: 100%;
}

.simplebar-track {
  pointer-events: all;
}

.simplebar-track.simplebar-vertical {
  width: 12px;
  background-color: transparent;
  border-radius: 10px;

  .simplebar-scrollbar {
    cursor: pointer;

    &:before {
      background-color: @thin-color;
      width: 7px;
      left: 3px;
      border-radius: 10px;
      box-shadow: 0 4px 4px fade(@black-darker, 15%);

    }

    &.simplebar-visible {
      &:before {
        opacity: 1;
      }
    }
  }
}
