.button--primary {
  .font(#fff 13px 17px, {
    font-family: @brand-family-2
  });
  padding: 11px 14px;
  border: 1px solid @brand-color;
  background-color: #0841DC;
  display: inline-block;
  min-width: 240px;
  //border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  min-height: 44px;

  &:hover {
    background-color: #1d5d90;
  }



  &.secondary {
    background: #A9A9A9;
    border-color: #A9A9A9;
  }
}

.button--primary-disabled {
    background-color: grey;
  cursor: default;

  &:hover {
    background-color: grey;
  }
}

.button--wide {
  height: 44px;
  min-width: 170px
}
